import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Typography from "/src/components/ui/base/typography"
import { graphql, Link } from "gatsby"
import Header from "../../components/ui/base/layout/header"
import TourCard from "../../components/ui/base/TourCard"
import Section from "../../components/ui/base/Section"
import { GatsbyImage } from "gatsby-plugin-image"
import classnames from "classnames"
import * as styles from "./index.module.scss"
import Breadcrumbs from "../../components/ui/base/Breadcrumbs"
import { createMarkup } from "../../util/tour"
import { GatsbySeo } from "gatsby-plugin-next-seo/src/meta/gatsby-seo"
import { useTranslation } from "react-i18next"
import {
  BASE_URL,
  DESTINATIONS_URL,
  PHOTO_TOURS_URL,
} from "../../util/constants"

const StatePage = ({ data }) => {
  const destination = data.strapiState
  const SEO = destination?.seo

  const { i18n: l } = useTranslation()
  const destinationLink = `${BASE_URL[l.language]}/${
    PHOTO_TOURS_URL[l.language]
  }/${DESTINATIONS_URL[l.language]}/${data.strapiState.slug}/`

  return (
    <>
      <GatsbySeo
        htmlAttributes={{ lang: l.language }}
        title={SEO?.metaTitle}
        language={l.language}
        description={SEO?.metaDescription}
        canonical={destinationLink}
      />
      <Header
        img={data.strapiState.image.localFile.childImageSharp.gatsbyImageData}
        theme="dark"
        size={"compact"}
      >
        <Container fluid="xl">
          <Row className="align-items-center">
            <Col lg={12}>
              <div className="intro_title text-center">
                <Typography className="animated fadeInDown" variant="heading1">
                  Viaggi Fotografici {data.strapiState.name}
                </Typography>
                <Breadcrumbs
                  elements={[
                    {
                      name: "WeShoot",
                      path: "/",
                    },
                    {
                      name: "Viaggi Fotografici",
                      path: "/viaggi-fotografici/",
                    },
                    {
                      name: "Destinazioni",
                      path: "/viaggi-fotografici/destinazioni/",
                    },
                    {
                      name: data.strapiState.name,
                      path: `/viaggi-fotografici/destinazioni/${data.strapiState.slug}/`,
                    },
                  ]}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Header>

      <Section
        variant={"white"}
        title={`I posti piú belli da fotografare in ${data.strapiState.name}`}
        subtitle={`Abbiamo raccolto per te le location piú incredibili dove scattare in ${data.strapiState.name}`}
      >
        <div className={classnames([styles.locationContainer])}>
          {destination && destination.description && (
            <div
              dangerouslySetInnerHTML={createMarkup(destination.description)}
            />
          )}
          {data?.allStrapiLocation.edges?.map(location => {
            if (
              location.node.pictures[0] &&
              location.node.pictures[0].image[0]
            ) {
              return (
                <div
                  className={classnames([styles.location])}
                  key={location.node.latitude}
                >
                  <div className={classnames([styles.imgContainer])}>
                    <GatsbyImage
                      className={classnames([styles.image])}
                      alt={location.node.title}
                      image={
                        location.node.pictures[0].image[0].localFile
                          .childImageSharp.gatsbyImageData
                      }
                    />
                  </div>
                  <div className={classnames([styles.text])}>
                    <Typography
                      variant="heading3"
                      className={classnames([styles.title])}
                    >
                      <Link
                        to={`/viaggi-fotografici/destinazioni/${data.strapiState.slug}/${location.node.slug}/`}
                      >
                        {location.node.title}
                      </Link>
                    </Typography>
                    <Typography
                      variant="heading6"
                      className={classnames([styles.description])}
                    >
                      {location.node.description}
                    </Typography>
                  </div>
                </div>
              )
            } else {
              return <></>
            }
          })}
        </div>
      </Section>

      <Section
        variant={"grey"}
        title={`Vieni a scattare con noi in ${data.strapiState.name}? 😍`}
        subtitle={
          "Non vediamo l'ora di conoscerti e di scattare insieme in questi bellissimi posti"
        }
      >
        <Row>
          {data?.allStrapiTour.edges?.map(tour => {
            return (
              <Col lg={4} md={6} className="zoomIn mb-2" key={tour.node.id}>
                <TourCard tour={tour.node} />
              </Col>
            )
          })}
        </Row>
      </Section>
    </>
  )
}

export default StatePage

export const query = graphql`
  query StateQuery($slug: String!) {
    strapiState(slug: { eq: $slug }) {
      name
      slug
      seo {
        metaDescription
        metaTitle
      }
      description
      locale
      image {
        localFile {
          ...ImageFragment
        }
      }
    }
    allStrapiTour(filter: { states: { slug: { eq: $slug } } }) {
      edges {
        node {
          id
          image {
            url
            localFile {
              ...ImageFragment
            }
          }
          price
          slug
          states {
            slug
          }
          places {
            slug
          }
          sessions {
            end
            id
            sessionId
            start
            status
            maxPax
            balance
            price
            deposit
            currency
            users {
              id
              firstName
              instagram
              profilePicture {
                localFile {
                  ...ImageFragment
                }
              }
            }
          }
          excerpt
          experienceLevel
          title
        }
      }
    }
    allStrapiLocation(filter: { state: { slug: { eq: $slug } } }) {
      edges {
        node {
          latitude
          longitude
          description
          title
          slug
          pictures {
            title
            image {
              name
              caption
              alternativeText
              localFile {
                ...ImageFragment
              }
            }
          }
        }
      }
    }
  }
`
